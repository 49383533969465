import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "My AWS Solutions Architect Associate Tips",
  "author": "tzookb",
  "type": "post",
  "date": "2020-02-11T13:50:08.000Z",
  "url": "/aws-solutions-architect-associate",
  "featuredImage": "./thumb.png",
  "categories": ["aws", "certificates"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I just passed my AWS solution architect associate exam :)`}</p>
    <p>{`It wasn't super easy but not that hard as well.`}</p>
    <p>{`I can say I did have some experience with AWS but not something to crazy.`}</p>
    <p>{`Unfortunately the test I took will expire on March 2020 but my ceritificate lasts for 3 years
and the knowledge for much more.`}</p>
    <p>{`I wanted to created this post just to share some of my experiences and maybe help others to get some motivation to do it as well.`}</p>
    <h2>{`Study Sources`}</h2>
    <h3>{`Udemy aCloudGuru course`}</h3>
    <p>{`I completed the full course, but I did notice a lot of holes that I needed to fill in by myself.
The course will help you a lot! but if that's the only source you will be learning from I can't promise you will pass the test.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.udemy.com/course/aws-certified-solutions-architect-associate/"
      }}>{`aCloud Guru solutions architect course on Udemy`}</a></p>
    <h3>{`Udemy Test Exams`}</h3>
    <p>{`I think you will be good with only one of them, but I enjoy learning so I took both.
You get 6 exams for each of them.`}</p>
    <p>{`I think the tests are a bit harder as they require deeper knowledge of some of AWS products that are not required for the test.
But worst case scenario, you will know more and be ready for the harder certificates in the future.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.udemy.com/course/aws-certified-solutions-architect-associate-amazon-practice-exams/"
      }}>{`Tutorials Dojo test on Udemy`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.udemy.com/course/aws-certified-solutions-architect-associate-practice-tests-k/"
      }}>{`Digital Cloud Training test on Udemy`}</a></p>
    <h3>{`AWS FAQ pages`}</h3>
    <p>{`Just making sure I'm clear, the test I did will get deprecated to March 2020 so no reason to put old links.
The above links for courses will probably get updated but links to FAQ may differ here.`}</p>
    <p>{`But important to know that is very useful to read through AWS FAQ pages for the products you are learning.`}</p>
    <p>{`For example:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://aws.amazon.com/s3/faqs/"
        }}>{`S3 FAQ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://aws.amazon.com/vpc/faqs/"
        }}>{`VPC FAQ`}</a></li>
    </ul>
    <h2>{`My Notes`}</h2>
    <p>{`As I went along with all the above material, I summarized everything on notes for rereading in the future.
I'll share the pdf`}</p>
    <a href="/aws-saa.pdf" target="_blank">Download PDF</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      